<template>
  <illness-packages-a v-if="Global.quote.abTestingVersion !== 'B' || Global.isAgentCustomerView || isPreSelectedUseA"></illness-packages-a>
  <illness-packages-b v-else></illness-packages-b>
  <!-- <illness-packages-a></illness-packages-a> -->
  <!-- <illness-packages-b></illness-packages-b> -->
</template>

<script>
import { defineAsyncComponent } from "vue";
import Global from "@/store/global.js";
import Mixin from "@/components/Mixin";
export default {
  data() {
    return {
      Global,
      isGetConfiguration: null,
      isPreSelectedUseA: false,
    };
  },
  mixins: [Mixin],
  name: "Package",
  beforeRouteLeave (to, from, next) {
    let bypass = ['policydetails1']
    if(bypass.includes(to.name)) next()
    if(window.pageYOffset > 0) {
      this.scrollToTopPage()
      next(false)
    } else next()
  },
  components: {
    illnessPackagesA : defineAsyncComponent(() =>  import('./critical-illness/Packages.vue')),
    illnessPackagesB : defineAsyncComponent(() =>  import('./critical-illness/PackagesB.vue')),
  },
  created() {
    let preselectedIllness = ['Cancer', 'Cardiovascular', 'Dengue']
    this.isPreSelectedUseA = preselectedIllness.includes(Global.defaultPreSelected);
  }
};
</script>